import "./node_modules/bootstrap/dist/css/bootstrap.css";
import "./src/styles/global.css";
import "@fontsource/prompt"
import "@fontsource/prompt/500.css"
import "./node_modules/slick-carousel/slick/slick.css"; 
import "./node_modules/slick-carousel/slick/slick-theme.css";

// simple-react-lightbox
import React from 'react'
import SimpleReactLightbox from 'simple-react-lightbox'
// USE THE IMPORT BELOW INSTEAD IF YOU ARE USING THE PRO VERSION
// import SimpleReactLightbox from 'simple-react-lightbox-pro'

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <SimpleReactLightbox>{element}</SimpleReactLightbox>
)