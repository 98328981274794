// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-distributor-js": () => import("./../../../src/pages/distributor.js" /* webpackChunkName: "component---src-pages-distributor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrial-thread-js": () => import("./../../../src/pages/industrial-thread.js" /* webpackChunkName: "component---src-pages-industrial-thread-js" */),
  "component---src-pages-other-products-js": () => import("./../../../src/pages/other-products.js" /* webpackChunkName: "component---src-pages-other-products-js" */),
  "component---src-pages-product-import-js": () => import("./../../../src/pages/product-import.js" /* webpackChunkName: "component---src-pages-product-import-js" */),
  "component---src-pages-product-industrial-thread-js": () => import("./../../../src/pages/product-industrial-thread.js" /* webpackChunkName: "component---src-pages-product-industrial-thread-js" */),
  "component---src-pages-product-sew-a-sack-js": () => import("./../../../src/pages/product-sew-a-sack.js" /* webpackChunkName: "component---src-pages-product-sew-a-sack-js" */),
  "component---src-pages-sew-a-sack-js": () => import("./../../../src/pages/sew-a-sack.js" /* webpackChunkName: "component---src-pages-sew-a-sack-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-cat-garment-js": () => import("./../../../src/templates/cat-garment.js" /* webpackChunkName: "component---src-templates-cat-garment-js" */),
  "component---src-templates-catagories-js": () => import("./../../../src/templates/catagories.js" /* webpackChunkName: "component---src-templates-catagories-js" */),
  "component---src-templates-product-02-js": () => import("./../../../src/templates/product02.js" /* webpackChunkName: "component---src-templates-product-02-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

